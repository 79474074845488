<template>
  <div class="auth-wrapper auth-v1">
    <div class="auth-inner py-2">

      <!-- Register v1 -->
      <b-card
        class="mb-0"
        style="max-width:500px;"
      >
        <validation-observer
          ref="registerForm"
          #default="{invalid}"
        >
          <b-form
            class="auth-register-form mt-2"
            @submit.prevent="validationForm"
          >
            <!-- username -->
            <b-form-group
              label="Name"
              label-for="name"
            >
              <validation-provider
                #default="{ errors }"
                name="Name"
                rules="required"
              >
                <b-form-input
                  id="name"
                  v-model="data.name"
                  :state="errors.length > 0 ? false:null"
                  name="register-name"
                  placeholder="John Doe"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- email -->
            <b-form-group
              label="Email"
              label-for="email"
            >
              <validation-provider
                #default="{ errors }"
                name="Email"
                rules="required|email"
              >
                <b-form-input
                  id="email"
                  v-model="data.email"
                  :state="errors.length > 0 ? false:null"
                  name="register-email"
                  placeholder="john@example.com"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Phone"
              label-for="phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Phone"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="phone"
                    v-model="data.phone"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="phone"
                    placeholder="604.000.0000"
                  />
                </b-input-group><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Agent ID"
              label-for="agent-id"
            >
              <validation-provider
                #default="{ errors }"
                name="Agent ID"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="agent-id"
                    v-model="data.agent_mls_loginName"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="agent-id"
                  />
                </b-input-group><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Member Type"
              label-for="member-type"
            >
              <validation-provider
                #default="{ errors }"
                name="Member Type"
                rules="required"
              >
                <v-select
                  id="member-type"
                  v-model="data.memberType"
                  :options="memberTypeOptions"
                  name="member-type"
                  :clearable="false"
                  :searchable="false"
                  label="text"
                  :reduce="memberType => memberType.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Role"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
              >
                <v-select
                  id="role"
                  v-model="data.role"
                  :options="roleOptions"
                  name="role"
                  :clearable="false"
                  :searchable="false"
                  label="text"
                  :reduce="role => role.value"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Office Name"
              label-for="office-name"
            >
              <validation-provider
                #default="{ errors }"
                name="Office Name"
                rules="required"
              >
                <!-- <v-select @search="getOfficeOptions" id="office-name" v-model="data.officeName" :options="officeOptions"/> -->
                <!-- <b-input-group class="input-group-merge" :class="errors.length > 0 ? 'is-invalid':null" >
                    <b-form-input id="office-name" v-model="data.officeName" :state="errors.length > 0 ? false:null" class="form-control-merge" name="office-name" />
                    </b-input-group>  -->
                <v-select
                  v-model="office"
                  label="officeName"
                  :filterable="false"
                  :options="officeOptions"
                  @search="onSearch"
                  @input="setOffice"
                >
                  <template slot="no-options">
                    type to search for offices
                  </template>
                  <template
                    slot="option"
                    slot-scope="officeOptions"
                  >
                    <div class="d-center">
                      <p>{{ officeOptions.officeName }}</p>
                      <p>{{ officeOptions.officeShortName }} | {{ officeOptions.officePhone }}</p>
                    </div>
                  </template>
                  <template
                    slot="selected-option"
                    slot-scope="officeOptions"
                  >
                    <div class="selected d-center">
                      <p>{{ officeOptions.officeName }}</p>
                      <p>{{ officeOptions.officeShortName }} | {{ officeOptions.officePhone }}</p>
                    </div>
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Office ID"
              label-for="office-id"
            >
              <validation-provider
                #default="{ errors }"
                name="Office ID"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="office-id"
                    v-model="data.agent_office_loginName"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="office-id"
                  />
                </b-input-group><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Office Phone"
              label-for="office-phone"
            >
              <validation-provider
                #default="{ errors }"
                name="Office Phone"
                rules="required"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="office-phone"
                    v-model="data.officeNumber"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="office-phone"
                  />
                </b-input-group><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Office Fax"
              label-for="office-fax"
            >
              <validation-provider
                #default="{ errors }"
                name="Office Fax"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="office-fax"
                    v-model="data.officeFax"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="office-fax"
                  />
                </b-input-group><small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <!-- password -->
            <b-form-group
              label="Password"
              label-for="password"
            >
              <validation-provider
                #default="{ errors }"
                name="Password"
                rules="required|min:8"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password"
                    v-model="data.password"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="register-password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility('password')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-form-group
              label="Password Confirm"
              label-for="password-confirm"
            >
              <validation-provider
                #default="{ errors }"
                name="Password Confirm"
                rules="required|password:@Password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
                >
                  <b-form-input
                    id="password-confirm"
                    v-model="data.password_confirmation"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="password-confirm"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility('password')"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- checkbox -->
            <b-form-group>
              <b-form-checkbox
                id="register-privacy-policy"
                v-model="data.status"
                name="checkbox-1"
              >
                I agree to
                <b-link>privacy policy & terms</b-link>
              </b-form-checkbox>
            </b-form-group>

            <!-- submit button -->
            <b-alert
              v-if="errorMsg"
              variant="danger"
              show
            >
              <div class="alert-body">
                {{ errorMsg }}
              </div>
            </b-alert>
            <b-button
              variant="primary"
              block
              type="submit"
              :disabled="invalid"
            >
              Sign up
            </b-button>
          </b-form>
        </validation-observer>

        <b-card-text class="text-center mt-2">
          <span>Already have an account? </span>
          <b-link :to="{name:'login'}">
            <span>Sign in instead</span>
          </b-link>
        </b-card-text>
      </b-card>
    <!-- /Register v1 -->
    </div>
  </div>

</template>

<script>
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BLink, BCardText, BForm,
  BButton, BFormInput, BFormGroup, BInputGroup, BInputGroupAppend, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: 'Password confirmation does not match',
})

export default {
  components: {
    // BSV
    BCard,
    BLink,
    BCardText,
    BForm,
    BButton,
    BFormInput,
    BFormGroup,
    BInputGroup,
    vSelect,
    BAlert,
    BInputGroupAppend,
    BFormCheckbox,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      memberTypeOptions: [
        { value: 'agent', text: 'Agent' },
        { value: 'team', text: 'Team' },
        { value: 'brokerage', text: 'Brokerage' },
      ],
      officeOptions: [],
      roleOptions: [
        { value: 'agent', text: 'Agent' },
        { value: 'manager', text: 'Mananger' },
        { value: 'assistant', text: 'Assistant' },
      ],
      office: {},
      data: {
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        phone: '',
        role: 'agent',
        agent_mls_loginName: '',
        memberType: 'agent',
        teamid: '',
        agent_office_loginName: '',
        officeName: null,
        officeNumber: '',
        officeFax: '',
        officeAddr: '',
        officeTel: '',
        status: '',
      },
      required,
      email,
      errorMsg: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    setOffice() {
      this.data.agent_office_loginName = this.office.officeShortName
      this.data.officeName = this.office.officeName
      this.data.officeNumber = this.office.officePhone
      this.data.officeFax = this.office.officeFax
      this.data.officeAddr = this.office.officeAddress
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true)
        this.getOfficeOptions(search, loading)
      }
    },
    getOfficeOptions(search, loading) {
      this.$store.dispatch('offices/getOfficeNames', { key: search }).then(response => {
        if (response.code === 200) {
          loading(false)
          this.officeOptions = response.data
        }
      }).catch(err => {
        console.error(err)
      })
    },
    validationForm() {
      if (!this.data.status) {
        this.errorMsg = 'Please agree to Privacy Policy & Terms and Conditions to continue'
        return
      }
      this.errorMsg = ''
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('users/register', this.data).then(response => {
            if (response.code === 200) {
              localStorage.setItem('token', response.data.token)
            }
          }).catch(err => {
            console.error(err)
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.vs__open-indicator{
  display:none;
}
</style>
